body {
    /*position: fixed;*/
    /*width: 100%;*/
    margin: 0;
    padding: 0;
    /*overflow: hidden;*/
}

#apple-sign-in-button .apple-auth-button {
    width: 150px !important
}

#apple-id-section {
    /*border: 1px solid red;*/
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;
    height: 20px;
    z-index: 999999999;
}

.apple-auth-button {
    /*top: 13px !important;*/
    height: 25px !important;
    /*width: 200px !important;*/
    /*background-color: white !important;*/
    /*fill: red !important;*/
    border: 0px solid red !important;
    width: 80px !important
}

.apple-auth-button-text {
    height: 90px !important;
}

#apple-sign-in-button svg path {
    transform: scale(0.6);
    fill: red;
}

#apple-sign-out-button svg path {
    transform: scale(0.6);
    fill: #a0a0a0;
}


#root {
    /*border:1px solid red;*/
    /*height: 200% !important;*/
    width: 100%;
}

#root > div {
    position: relative;
    /*border: 4px solid black;*/
    /*height: 100vh;*/
    overflow: hidden;
}

.App {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100vh;
    position: relative;
    /*border: 3px solid green;*/
}

.board-header {
    font-size: 12px;
    /*padding: 10px;*/
    margin: 0;
    color: #c0c0c0;
    /*background-color: #ffffff;*/
    display:flex;
    /*border: 1px solid red;*/
    position: absolute;
    z-index: 99999999999;
}

.board-header-home {
    position: relative !important;
    z-index:-1;
}

.logo {
    /*border: 1px solid #000;*/
    padding: 4px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 18px;
    vertical-align: center;
    /*margin-right: 0px;*/
    background-color: rgba(0, 205, 255, .4);
    color: black;
    text-align: center;
}

.logo a {
    text-decoration: none;
    color: white;
}
.release-decal {
    font-size: 8px;
    color: red;
    margin-left: -10px;
    margin-top: 4px;
    position: relative;
}
.click-editor-field {
    justify-self: stretch;
    cursor: pointer;
    font-weight:normal;
    font-size: 20px;
    color: #c0c0c0;
    /*border:1px solid red;*/
    /*height: 100%;*/
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
}
.click-editor-field input[type=text]{
    font-weight:normal;
    font-size: 20px;
    width:400px;
}
.action-bar {
    display:flex;
    align-self: stretch;
    flex-direction:row;
    /*border: 1px solid blue;*/
    align-items: center;
}
.board-action {
    /*padding: 4px;*/
    text-decoration: none;
    /*background-color: rgba(0, 205, 255, .4);*/
    background-color: rgba(0, 205, 255, .9);
    color: white;
    height:15px;
    margin:2px;
    padding:4px;
    border-radius: 3px;
    /*line-height: 10px;*/
    text-transform: uppercase;
    /*display: block;*/
    /*border: 1px solid red;*/
    vertical-align: middle;
    line-height:15px;
    display:inline-block;
}

@keyframes barberpole2 {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 30px 0px;
    }
}

.board-loading {
    /*border: 1px solid red;*/
    padding: 2px;
    background-size: 30px 30px;
    background-image: linear-gradient(
            45deg,
            rgba(222, 222, 222, 0.4) 25%,
            transparent 25%,
            transparent 50%,
            rgba(222, 222, 222, 0.4) 50%,
            rgba(222, 222, 222, 0.4) 75%,
            transparent 75%,
            transparent
    ) !important;

    animation: barberpole2 0.2s linear infinite;
}

.log-line {
    font-size: 10px;
    color: #a0a0a0;
    padding: 5px;
}
/*.board-list {*/
    /*font-size: 15px;*/
    /*overflow: scroll;*/
    /*-webkit-overflow-scrolling: touch;*/
    /*height:100%;*/
    /*padding: 10px;*/
    /*margin:0;*/
    /*display: flex;*/
/*}*/

/*.board-entry {*/
    /*margin: 1px;*/
    /*padding: 10px;*/
    /*display: block;*/
    /*list-style: none;*/
    /*!*width: 120px;*!*/
    /*!*height: 100px;*!*/
    /*overflow: hidden;*/
    /*!*background-color: #fafafa;*!*/
/*}*/


.board-list {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, 220px);
    /*grid-template-columns: repeat(4, 1fr);*/
    margin: 0;
}
.board-entry {
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    list-style: none;
    margin: 0;
    padding: 14px;
    border: 0px solid #f0f0f0;
border-radius: 4px;
    position: relative;
    overflow:hidden;
}

.board-image-snippet-fader {
    top: -1px;
    left: -1px;
    width:100%;
    height:100%;
    /*border:3px solid red;*/
    z-index: -990;
    position:absolute;
    background-color: rgba(225,255,255,0.6);
    /*background-image: linear-gradient(*/
            /*45deg,*/
            /*rgba(200, 200, 200, 0.1) 25%,*/
            /*transparent 25%,*/
            /*transparent 50%,*/
            /*rgba(200, 200, 200, 0.1) 50%,*/
            /*rgba(200, 200, 200, 0.1) 75%,*/
            /*transparent 75%,*/
            /*transparent*/
    /*) !important;*/

    background-size: 80px 10px;
    border-radius: 5px;
}
.board-image-snippet {
    top: -1px;
    left: -1px;
    width:100%;
    height:100%;
    /*border:3px solid red;*/
    z-index: -999;
    position:absolute;
    opacity: 0.9;
    background-size: 120%;
    background-color: #e0e0e0;
    border-radius: 5px;
}
.board-entry-blank {
    background-color: transparent;
    height: 50px;
}

.board-name {
    position:absolute;
    margin-left: -14px;
    margin-top: -14px;
    bottom:1px;
    /*background-color: white;*/
    /*background-color: rgba(255,255,255,0.);*/
    display: inline-block;
    padding: 4px;
    text-decoration: none;
    /*font-weight: bold;*/
    color:black;
    border-top-right-radius: 4px;
    /*opacity: 0.8;*/
    /*border-bottom-left-radius: 4px;*/
    /*border:1px solid #e0e0e0;*/
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
}
.board-name a {
    /*background-color: rgba(0,0,0,0.5);*/
    display: inline-block;
    /*padding: 4px;*/
    text-decoration: none;
    color:black;
}
.board-info {
    margin-top: 4px;
    color: #c0c0c0;
    font-size: 12px;
    /*opacity: 0.5;*/
    /*font-style: italic;*/
    /*padding: 4px;*/
    /*border: 1px solid red;*/
}
.board-access-public {
    /*display: inline-block;*/
    /*width: 5px;*/
    /*height: 5px;*/
    /*margin: 3px;*/
    /*background-color: #aaeeaa;*/
    /*border-top: 2px solid #aaeeaa;*/
}
.board-access-private {
    /*display: inline-block;*/
    /*width: 5px;*/
    /*height: 5px;*/
    /*margin: 3px;*/
    border-top: 2px solid #eeaaaa;
}
.board-access-shared {
    /*display: inline-block;*/
    /*width: 5px;*/
    /*height: 5px;*/
    /*margin: 3px;*/
    border-top: 2px solid #e0e090;
}
.drop-zone {
    border: 0px solid red;
}

.viewport {
    /*border-top: 1px dotted #f0f0f0;*/
    /*border: 10px solid green;*/
    height: 500px;
    position: relative;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    /*background-color: #f0f0f0;*/
    /*background-color: red;*/
    flex: 1;
    /*background: url("/crosshair_10x10.png") -1px -1px;*/
}

.canvas {
    /*border: 1px solid blue;*/
    width: 1000px;
    height: 1000px;
    position: relative;
    background: url("/crosshair_10x10.png") -1px -1px;
    /*background: url("/richard feynman.png");*/
    background-color: #ffffff;
    /*background-image: linear-gradient( 0deg, blue, green 40%, red );*/
    /*background-color: blue;*/
    /*background: linear-gradient(black, white);*/
transition: width 0.1s linear,
height 0.1s linear;
}

.canvas-moving {
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
}

.surface {
    /*border: 1px solid red;*/
    /*width: 1000px;*/
    /*height: 1000px;*/
    top: 0;
    left: 0;
}

.canvas-edge-marker-bottom {
    position: absolute;
    height: 40px;
    /*border: 1px solid red;*/
    bottom:0;
    left: 0;
    right:0;
    border-top: 1px dashed #707070;
    margin-top:-100px;
    transition: bottom 5s linear;
/*background-color:red;*/
}
.scrap {
    /*user-select: none;*/
    position: absolute;
    width: 1px;
    height: auto;
    top: 20px;
    left: 40px;

    border: 1px solid transparent;
    border: 1px solid #e0e0e0;
    /*border: 1px solid #c0c0c0;*/
    /*border: 10px solid red;*/
    /*border-radius: 3px;*/
    /*background-color: #fafafa;*/
    /*padding: 10px;*/

    /*overflow: scroll;*/
    transition: border-style 0.1s linear,
    border-color 0.1s ease-in-out,
    box-shadow 0.2s ease-in-out
}

.scrap-settling {
    /*border: 3px solid red !important;*/
    transition: border-style 0.2s linear,
    border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out,
    top 0.2s ease-in-out,
    left 0.2s ease-in-out !important;
}
.scrap-unsnapping {
    /*border: 3px solid red !important;*/
    transition:
    /*border-style 0.2s linear,*/
    /*border-color 0.2s ease-in-out,*/
    /*box-shadow 0.2s ease-in-out,*/
    top 0.1s ease-out,
    left 0.1s ease-out !important;
}

.scrap-margin-marker {
    border-bottom: 1px solid red;
    /*border-left: 1px solid red;*/
    /*border-right: 1px solid red;*/
    top: -4px;
    /*height: 4px;*/
    position: absolute;
    box-sizing: border-box;
    font-size:9px;
    line-height:10px;
    color:red;
    text-align:center;
}
.scrap-snap-marker {
    border-left: 1px solid red;
    /*border-left: 1px solid red;*/
    /*border-right: 1px solid red;*/
    /*top: -4px;*/
    /*height: 4px;*/
    position: absolute;
    box-sizing: border-box;
    font-size:9px;
    line-height:10px;
    color:red;
    text-align:center;
    display:flex;
    align-items: center
}

.scrap-candidate {
    border: 3px solid orange !important;
}

.scrap-loading {
    border-style: dashed;
    border-color: #e0e0ff;
    background-color: rgba(210, 210, 210, 0.1);
    /*pointer-events: none;*/
}

.scrap-delay-load {
    border: 1px dotted #a0a0a0 !important;
    /*border: 13px solid blue !important;*/
}

/*
.scrap:hover {
    z-index: 99999999 !important;
    border: 1px solid #c0c0c0;
    opacity: 0.9;
}
*/
.scrap-selected {
    /*opacity: 0.8;*/
    z-index: 99999999 !important;
    /*border:4px solid black;*/
    /*border-color: black;*/
    border-color: rgba(0, 205, 255, 0.9);

    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.1);
    /*transform: translate(-3px, -3px);*/

    /*transition: top 0,*/
    /*left 0,*/
    /*width 0,*/
    /*height 0;*/

}

.scrap-moving {
    opacity: 0.8;
}

@keyframes barberpole {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 1px 130px;
    }
}

.scrap-error {
    z-index: 99999999 !important;
    border: 4px solid red;
}

.scrap-zoom {
    opacity: 1.0 !important;
    padding: 10px;
}

.scrap-dull {
    /*pointer-events: none;*/
    opacity: 0.1 !important;
}

.scrap-busy {
    z-index: 99999999 !important;
    /*border:10px solid red;*/
    /*background-color:rgba(255,0,0,0.2);*/
    opacity: 0.9;
    pointer-events: none;

    background-size: 30px 30px;
    background-image: linear-gradient(
            45deg,
            rgba(200, 200, 200, 0.4) 25%,
            transparent 25%,
            transparent 50%,
            rgba(200, 200, 200, 0.4) 50%,
            rgba(200, 200, 200, 0.4) 75%,
            transparent 75%,
            transparent
    ) !important;
    animation: barberpole 0.2s ease-in-out alternate-reverse infinite;
}

.scrap-hot {
    /*border: 1px solid #ffaaaa;*/
    box-shadow: 3px 3px 5px rgba(255, 89, 52, 0.2);
}
.scrap:hover .image-handle {
    visibility: visible !important;
}

.scrap:hover .image-annotation {
    /*opacity: 0.8;*/
}

.image-frame {
    user-select: none;
    width: 200px;
    height: 200px;
    /*border: 1px solid red;*/
}

.image-frame img {
    /*transition: width 0.2s ease-in-out,*/
    /*height 0.2s ease-in-out;*/
}

.video-frame {
    /*border: 1px solid red;*/
    /*padding: 3px;*/
    /*height: 200px;*/
    /*width: 200px;*/
}
.scrap-selected .image-frame img {
    /*transition: width 0,*/
    /*height 0;*/
}

.blob-frame {
    background-color: #fafafa;
    padding: 10px;
    font-size: 14px;
}

.blob-download-link {
    color: black;
    text-decoration: none;
}
.blob-download-link:hover {
    color: blue;
    text-decoration: underline;
}

.blob-meta {
    color: #a0a0a0;
    margin-top: 4px;
    font-size: 12px;
    text-align: right;
}

.image-annotation {
    position: absolute;
    left: 3px;
    bottom: 3px;
    font-size: 10px;
    opacity: 0;
    /*pointer-events: none;*/
}
.stupid-annotation-link-container {
    /*position: relative;*/
    padding-left: 0px;
}
.annotationLink {
    position: relative;
    /*bottom:0;*/
    left:0;
    text-decoration: none;
    color:blue;
    z-index:99999;
    border: 1px solid blue;
    border-radius: 2px;
    margin: 2px;
}
.scrap-annotation {
    font-size: 12px;
    padding: 10px;
    background-color: #f0f0f0;
    bottom: 0;
    position: absolute;
    white-space: pre-wrap;
    /*border: 3px solid red;*/
}
.scrap-editing-annotation {
    /*border: 1px solid red;*/
    border-bottom: 3px solid rgba(0, 205, 255, 0.5);
    transition: border-bottom 0.3s ease-in-out;
}
.waypoint-marker {
    position: absolute;
    background-color: red;
    color: white;
    border: 1px solid white;

    width: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 15px;
    border-radius: 100px;
    left: -10px;
    top: -10px;
    pointer-events: none;
    z-index: 9999999;
}

.image-handle {
    visibility: hidden !important;
    border-bottom: 3px solid rgba(0, 255, 255, 0.7);
    border-right: 3px solid rgba(0, 255, 255, 0.7);;
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30px;
    height: 30px;
    z-index: 99999999;
}

.image-handle:hover {
    cursor: none;
    border-bottom-width: 5px;
    border-right-width: 5px;
    right: -6px;
    bottom: -6px;
}

.scrap-busy .image-handle {
    display: none !important;
}

.scrap:hover .image-pie {
    visibility: visible !important;
}

.selectionArea {
    border: 1px solid rgba(0, 205, 255, 0.5);
    position: absolute;
    width: 10px;
    height: 10px;
    z-index:99999999;
    background-color: rgba(0, 205, 255, 0.1);

    /*background-color: rgba(0,0,0,0.02);*/
}
.image-pie {
    /*visibility: hidden !important;*/
    /*border-top: 3px solid rgba(0,255,255,0.7);*/
    /*border-right: 3px solid rgba(0,255,255,0.7);;*/

    /*background-color: white;*/
    /*border: 3px solid rgba(0,0,0,0.7);*/
    /*border-radius: 20px;*/
    /*border: 1px solid rgba(0, 255, 255, 0.7);*/

    margin-left: 30px;
    cursor: pointer;
    /*position: absolute;*/
    /*right: 0;*/
    /*top: 0;*/
    /*left:0;*/
    /*width: 130px;*/
    /*height: 130px;*/
    z-index: 9999999999999;
    /*background-color: white;*/
    overflow: hidden;
    display: flex;
color:white;
    /*line-height: 14px;*/
    text-transform: uppercase;
    align-items: center;
}

.selection-action {
    /*border: 1px solid blue;*/
    /*background-color: rgba(0, 255, 255, 0.7);*/
    background-color: rgba(0, 205, 255, .9);
    margin-left: 4px;
    display:inline-block;
    font-size:10px;
    line-height: 14px;
}


@keyframes rot {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.export-spinner {
    border:1px solid white;
    border-radius: 20px;
    width:10px;
    height:10px;
    vertical-align: middle;
    display:inline-block;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
    animation: rot 1s ease-in-out infinite alternate;
}
/*.image-pie > * {*/
    /*visibility: hidden;*/
/*}*/

/*.image-pie:hover > * {*/
    /*visibility: visible;*/
/*}*/

/*.image-pie:hover {*/
    /*!*cursor:none;*!*/
    /*!*border-top-width: 5px;*!*/
    /*!*border-right-width: 5px;*!*/
    /*background-color: white;*/
    /*!*right: -50px;*!*/
    /*!*top: -50px;*!*/
    /*width: 100px;*/
    /*height: 100px;*/
    /*z-index: 9999999999999;*/
/*}*/

.image-pie > div {
    padding: 4px;
    border-radius: 3px;
}

.scrap-busy .image-pie {
    display: none !important;
}


.example-enter {
    opacity: 0.01;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.example-leave {
    opacity: 1;
}

.example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

.mini-map {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 200px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: white;
    z-index: 999999999;
    pointer-events: none;
    overflow: hidden;
    opacity: 0.9;
}

.mini-map-viewport {
    position: absolute;
    border: 1px solid #a0a0a0;
    z-index: 99999;
    transition: top 0.04s linear,
    left 0.04s linear;
}

.mini-scrap {
    position: absolute;
    background-color: #f0f0f0;
    border: 1px solid #e0e0e0;
    text-align:center;
    line-height: 10px;
    font-size: 8px;
    color: red;
    vertical-align: text-bottom;
}

.mini-scrap-active {
    /*border: 1px solid black;*/
    background-color: #909090;
    z-index: 99999;
    opacity: 0.5;
}

.mini-scrap-hot {
    background-color: orangered ;
    /*border-color: red;*/
    border: 1px solid red;
    /*border-right: 1px solid orangered;*/
}